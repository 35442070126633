:root {
  --warning: #e2c103;
  --secondary: #161616;
  --white: #fff;
}

@font-face {
  font-family: futur;
  src: url(./font/futur.ttf);
}

@font-face {
  font-family: futur-book;
  src: url(./font/Futura\ Book\ font.ttf);
}

@font-face {
  font-family: futur-bold;
  src: url(./font/Futura\ Bold\ font.ttf);
}
html {
  scroll-behavior: smooth;
}
.App {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: var(--white);
  font-family: futur-book;
  font-size: 17px;
  overflow-x: hidden;
}

video {
  position: relative;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  width: 100%;
}

.scrollArrow {
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  border: 5px solid var(--white);
  border-radius: 50%;
  scroll-behavior: smooth;
  z-index: 11111;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: var(--warning);
}

.heroHeading {
  color: var(--white);
  margin-bottom: 2em;
  font-family: futur-bold;
  text-align: center;
  letter-spacing: 3px;
}

.heroHeading::after {
  content: "";
  display: block;
  width: 5%;
  height: 5px;
  background-color: var(--warning);
  margin: 0.5em auto;
}

.mainHeading {
  color: var(--white);
  margin-bottom: 2em;
  font-family: futur-bold;
}

.mainHeading::after {
  content: "";
  display: block;
  width: 5%;
  height: 5px;
  background-color: var(--warning);
  margin: 0.5em 0;
}
/*  <<<<<<<<<<<<<<<<<<<<<<<< home >>>>>>>>>>>>>>>>>>>>>>>>  */
#home .container{
  text-align: center;
}

#home a {
  text-decoration: none;
  background-color: var(--white);
  padding: 15px 25px;
  margin-left: 404px;
  border-radius: 23px;
  font-weight: 500;
  text-align: center;
  color: var(--warning);
  font-size: 18px;
  margin-bottom: 20px;
  height: 100%;
  width: 25%;
  justify-content: center;

}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Hero Header >>>>>>>>>>>>>>>>>>  */

#heroHeader {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  color: var(--white);
  right: 0;
  top: 0;
  left: 0;
  z-index: 1111;
  backdrop-filter: blur(10px);
  padding: 1em 0;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

#heroHeader a {
  color: var(--white);
  margin-right: 0.5em;
}

#heroHeader a:hover {
  color: var(--warning);
}

#heroHeader .active {
  color: var(--warning);
}

#heroHeader .connectBtn {
  color: var(--white);
  display: inline-block;
  padding: 0.6em 1.5em;
  background-color: var(--warning);
  border: none;
  cursor: pointer;
  transition: 300ms all ease-in-out;
  border-radius: 5px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< Hero Section >>>>>>>>>>>>>>>>>>>>>>>>>>>  */

#heroSection {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
}

#heroSection h1 {
  margin-bottom: 1em;
  font-family: futur-bold;
}

#heroSection h1::after {
  content: "";
  display: block;
  width: 15%;
  height: 5px;
  background-color: var(--warning);
  margin-top: 0.5em;
}

#heroSection button {
  color: var(--white);
  display: inline-block;
  padding: 0.7em 1.5em;
  background-color: var(--warning);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all linear 300ms;
}

#heroSection a {
  text-decoration: none;
  background-color: var(--white);
  padding: 15px 25px;
  margin-left: 15px;
  border-radius: 50px;
  font-weight: 500;
  color: var(--warning);
  font-size: 18px;
}

#heroSection .design-layer::before {
  position: absolute;
  content: "";
  top: 430px;
  right: 0px;
  width: 170px;
  height: 140px;
  background: var(--warning);
  z-index: 111;
  -webkit-clip-path: polygon(34% 32%, 64% 46%, 100% 31%, 100% 55%, 65% 70%, 33% 56%, 0 61%, 0 39%);
  -webkit-box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
  box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
}

#heroSection .design-layer::after {
  position: absolute;
  content: "";
  top: 480px;
  right: 0px;
  width: 170px;
  height: 140px;
  background: var(--warning);
  z-index: 111;
  -webkit-clip-path: polygon(34% 32%, 64% 46%, 100% 31%, 100% 55%, 65% 70%, 33% 56%, 0 61%, 0 39%);
  -webkit-box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
  box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
}
/*  <<<<<<<<<<<<<<<<<<<<<<<< Countdown >>>>>>>>>>>>>>>>>>>>>>>>  */
#Pre-sale .title {
  margin-bottom: 2em;
  text-align: center;
  letter-spacing: 4px;

}

#Pre-sale .container{
  text-align: center;
}

#Pre-sale .buttons{
  color: var(--white);
  display: inline-block;
  padding: 0.7em 1.5em;
  background-color: var(--warning);
  border: none;
  margin-bottom: 30px;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all linear 300ms;
  margin-top: 35px;
}


#Pre-sale .button {
  text-decoration: none;
  background-color: var(--white);
  padding: 15px 25px;
  margin-left: 15px;
  border-radius: 50px;
  font-weight: 500;
  color: var(--warning);
  font-size: 18px;
  
}

#Pre-sale button {
  color: var(--white);
  display: inline-block;
  padding: 0.7em 1.5em;
  background-color: var(--warning);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all linear 300ms;
  margin-bottom: 50px;
}
#Pre-sale .info{
  text-decoration: underline;
  color: var(--white);
  font-weight: 500;
  font-size: 18px;
}



/*  <<<<<<<<<<<<<<<<<<<<<<<< ROADMAP >>>>>>>>>>>>>>>>>>>>>>>>  */

#roadmap {
  padding: 2em 0;
}

#roadmap .mainTimeline {
  position: relative;
}

#roadmap .mainTimeline::before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background-color: var(--secondary);
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#roadmap .title {
  font-size: 19px;
  font-family: futur-bold;
  line-height: 24px;
  margin: 0 0 15px 0;
}

#roadmap .timeline {
  margin-bottom: 40px;
  position: relative;
}

#roadmap .timeline:hover .icon::before {
  left: 3px;
}

#roadmap .timeline:hover .icon::after {
  left: -3px;
}

#roadmap .timeline::after {
  content: "";
  display: block;
  clear: both;
}

#roadmap .timeline:nth-child(2n) .dateContent {
  float: right;
}

#roadmap .timeline:nth-child(2n) .dateContent::before {
  left: 10px;
  content: "";
  width: 36.5%;
  height: 2px;
  background-color: var(--secondary);
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

#roadmap .timeline:nth-child(2n) .timelineContent {
  padding: 20px 50px 20px 0;
  text-align: right;
  float: left;
}

#roadmap .icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#roadmap .icon::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: all 0.33s ease-out 0s;
  background: var(--warning);
  border: 2px solid var(--secondary);
  left: -3px;
}

#roadmap .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: all 0.33s ease-in-out;
  border: 2px solid var(--secondary);
  left: 3px;
}

#roadmap .dateContent {
  width: 50%;
  margin-top: 22px;
  position: relative;
}

#roadmap .dateContent::before {
  content: "";
  width: 36.5%;
  height: 2px;
  background-color: var(--secondary);
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

#roadmap .dateOuter {
  width: 125px;
  height: 125px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1;
}

#roadmap .dateOuter::before {
  content: "";
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.33s ease-out 0s;
  background-color: var(--warning);
  border: 2px solid var(--secondary);
  left: -6px;
}

#roadmap .timeline:hover .dateOuter::before {
  left: 6px;
}

#roadmap .timeline:hover .dateOuter::after {
  left: -6px;
}

#roadmap .dateOuter::after {
  content: "";
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.33s ease-in-out;
  background-color: var(--warning);
  border: 2px solid #232323;
  left: 6px;
}

#roadmap .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0;
  z-index: 111;
}

#roadmap .month {
  font-size: 18px;
  font-weight: 700;
  color: white;
}

#roadmap .year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: var(--white);
  line-height: 36px;
}

#roadmap .timelineContent {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right;
}

#roadmap .title {
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 0.9em 0;
}

/* Gallery */

#gallerySection {
  padding: 2em 0;
  text-align: center;
}

#gallerySection img {
  /* height: 100%; */
  overflow: hidden;
  border-radius: 10px;
  /* margin-bottom: 1em; */
}

/* <<<<<<<<<<<<<<<<<<<<<<< TEAM >>>>>>>>>>>>>>>>>>>>*/

#teamSection {
  padding: 2em 0;
  text-align: center;
  position: relative;
}

#teamSection h5 {
  color: var(--white);
  margin-bottom: 1em;
  font-family: futur-bold;
}

#teamSection .cards {
  text-align: center;
}

#teamSection img {
  max-width: 85%;
  border-radius: 30% 84% 28% 76% / 87% 23% 69% 29%;
  border: 6px solid var(--warning);
  margin-bottom: 2em;
  filter: grayscale(100%);
  transition: all ease-in-out 200ms;
}

#teamSection img:hover {
  filter: unset;
}

#teamSection .design-layer::before {
  position: absolute;
  content: "";
  top: 430px;
  left: 0px;
  width: 170px;
  height: 140px;
  background: var(--warning);
  z-index: 111;
  -webkit-clip-path: polygon(34% 32%, 64% 46%, 100% 31%, 100% 55%, 65% 70%, 33% 56%, 0 61%, 0 39%);
  -webkit-box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
  box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
}

#teamSection .design-layer::after {
  position: absolute;
  content: "";
  top: 480px;
  left: 0px;
  width: 170px;
  height: 140px;
  background: var(--warning);
  z-index: 111;
  -webkit-clip-path: polygon(34% 32%, 64% 46%, 100% 31%, 100% 55%, 65% 70%, 33% 56%, 0 61%, 0 39%);
  -webkit-box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
  box-shadow: 0 31px 35px rgb(0 0 0 / 10%);
}

/* <<<<<<<<<<<<<<<<<<<<<<< FAQ >>>>>>>>>>>>>>>>>>>>> */

#faqSection {
  padding: 2em 0;
}

#faqSection h2 {
  font-size: 2em;
}

#faqSection button {
  color: var(--warning);
  border: none !important;
  padding: 1em 0em;
  font-family: futur-bold;
  font-size: 17px;
  width: 100%;
  background-color: transparent;
  text-align: left;
  outline: none;
}

#faqSection button:focus {
  box-shadow: none;
}
.accordion-item {
  background-color: #343a40 !important;
  color: #fff !important;
  padding: 0 1em;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-body {
  padding-bottom: 1em;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< subscribe >>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

#subscribe {
  padding: 30px 0;
  margin-bottom: -70px;
}

#subscribe .sub-main {
  padding: 30px 0;
  background-color: var(--warning);
  border-radius: 50px;
}

#subscribe .sub-main h3 {
  padding-left: 60px;
  font-size: 26px;
  color: var(--white);
  font-weight: 700;
}

#subscribe .sub-main a {
  text-decoration: none;
  background-color: var(--white);
  padding: 15px 25px;
  border-radius: 50px;
  font-weight: 500;
  color: var(--warning);
  font-size: 18px;
}

/* <<<<<<<<<<<<<<<<<<<<< FOOTER >>>>>>>>>>>>>>>>>>>>>>>  */

#lastFooter {
  background-color: var(--secondary);
  border-top: 2px dashed var(--secondary);
  padding: 25px 0;
}

#lastFooters {
  
  padding: 25px 0;
}

#lastFooter h2 {
  color: var(--white);
  font-size: 16px;
  margin-bottom: 0;
  font-family: futur-bold;
}

#lastFooter .footerSocial {
  padding-top: 11px;
}

#lastFooter .footerSocial i {
  text-decoration: none;
  color: var(--warning);
  transition: 200ms all ease-in-out;
}

#lastFooter .footerSocial i:hover {
  text-decoration: none;
  color: var(--white);
  background-color: var(--warning);
  border: 1px solid var(--warning);
}

#lastFooter .fop-btm a {
  color: var(--warning);
  font-family: futur-bold;
  text-decoration: none;
}

#lastFooter i {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
}

#lastFooters i {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
}

#lastFooter img {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ClubFeatures >>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
#ClubFeatures h4 {
  font-size: 24px;
  color: white;
  padding: 20px 0;
}
#ClubFeatures p {
  font-size: 17px;
  color: white;
  line-height: 26px;
}
#ClubFeatures a {
  display: inline-block;
  font-size: 27px;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  background: #e2c103;
}
#ClubFeatures .featureContent {
  text-align: end;
  padding: 35px 31px;
  background-color: rgba(8, 57, 94, 0.5);
  margin-top: 30px;
  position: relative;
}
#ClubFeatures .featureContent.top::before {
  content: "";
  position: absolute;
  top: -102px;
  width: 3px;
  height: 100px;
  background-color: #161616;
  left: 5px;
}
#ClubFeatures .featureContent.top::after {
  position: absolute;
  content: "";
  top: -102px;
  left: 2px;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  background: #e2c103;
}
#ClubFeatures .featureContent.bottom::before {
  content: "";
  position: absolute;
  bottom: -102px;
  width: 3px;
  height: 100px;
  background-color: #161616;
  right: 5px;
}
#ClubFeatures .featureContent.bottom::after {
  position: absolute;
  content: "";
  bottom: -102px;
  right: 2px;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  background: #e2c103;
}
#ClubFeatures .featureContent.right::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: -55px;
  width: 3px;
  height: 100px;

  background-color: #161616;
}
#ClubFeatures .featureContent.right::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: -102px;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  background: #e2c103;
}
#ClubFeatures .featureContent.left::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: -55px;
  width: 3px;
  height: 100px;

  background-color: #161616;
}
#ClubFeatures .featureContent.left::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: -102px;
  height: 10px;
  border-radius: 50%;
  width: 10px;
  background: #e2c103;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< lg screen >>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.saleEnd {
  padding: 6em 0;
}

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 2em 0.5em;
  text-align: center;
}
.wrapper h1 {
  margin-top: 0;
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 0.7em;
}
.wrapper .mintingSection {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  border: 4px solid #e2c103;
  border-radius: 2em;
  padding: 3em 1em;
}
.wrapper h4 {
  font-size: 2.2em;
  margin-top: 0;
  margin-bottom: 0;
}
.wrapper p {
  font-size: 1.2em;
  margin: 0;
}
.wrapper h6 {
  color: blue;
  font-size: 2em;
  margin-bottom: 0;
}
.wrapper .counter {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  margin: 1.5em auto;
  border-radius: 3em;
  overflow: hidden;
}
.wrapper .counter button {
  background-color: transparent;
  color: #fa5c7c;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 3em;
  padding: 0;
  width: 25%;
  cursor: pointer;
  transition: 200ms all ease-in-out;
}
.wrapper .counter button:hover {
  opacity: 0.8;
}
.wrapper .counter span {
  width: 50%;
  margin: 0 0.2em;
  background-color: transparent;
  color: #fa5c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}
.wrapper .mintBtn {
  font-size: 1.5em;
  font-weight: 700;
  border: 4px solid #fa5c7c;
  background-color: transparent;
  transition: 200ms all ease-in-out;
  color: #fa5c7c;
  border-radius: 2em;
  padding: 0.5em;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}
.wrapper .mintBtn:hover {
  color: #fff;
  background-color: #fa5c7c;
}
.mintingSection > p:last-child {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 1em;
}

@media only screen and (max-width: 992px) {
  body {
    font-size: 14px;
  }

  video {
    top: 80px;
    bottom: 0;
  }

  /* Header */
  

  #heroHeader {
    text-align: center;
  }

  #heroHeader .fas {
    color: var(--white) !important;
  }
  #heroHeader .fas {
    color: var(--white) !important;
  }

  /* Hero Section  */

  #heroSection .design-layer {
    display: none;
  }

  #heroSection {
    top: 85px;
    bottom: unset;
    text-align: left;
  }

  #heroSection h1 {
    font-size: 17px;
    margin-top: 3em;
  }

  #heroSection p {
    font-size: 14px;
  }

  #heroSection h1::after {
    margin: auto;
  }

  #heroSection button {
    padding: 0.4em 1em;
  }

  /* roadmap */

  #roadmap {
    margin-top: 80px;
  }

  #roadmap .mainTimeline {
    text-align: center;
  }

  #roadmap .icon {
    margin: auto 0 !important;
  }

  #roadmap .mainTimeline::before {
    margin: 0;
  }

  #roadmap .dateContent {
    width: 100%;
    margin-bottom: 1em;
  }

  #roadmap .dateContent::before {
    display: none;
  }

  #roadmap .timelineContent {
    width: 100%;
    padding: 1em;
    float: unset;
  }

  #roadmap .timeline:nth-child(2n) .timelineContent {
    padding: 1em;
    text-align: right;
    float: unset;
  }

  #roadmap .timeline:nth-child(2n) .dateContent {
    float: unset;
    display: block;
  }

  #roadmap .timeline:nth-child(2n) .dateContent::before {
    display: none;
  }

  #roadmap .timeline:nth-child(2n) .timelineContent {
    padding: 1em;
    text-align: center;
    float: unset;
    display: block;
  }

  /* gallery */

  /* team */
  #teamSection .design-layer {
    display: none;
  }
  /* faq */

  /* subscribe */

  #subscribe .sub-main {
    padding: 20px 0;
    background-color: var(--warning);
    border-radius: 10px;
  }

  #subscribe .sub-main h3 {
    padding-left: 0;
    font-size: 14px;
  }

  #subscribe .sub-main a {
    margin-top: 1em;
    display: inline-block;
  }
}
